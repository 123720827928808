import Vue  		from 'vue'
import Vuex 		from 'vuex'

import customers    from './modules/customers.js';
import products   	from './modules/products.js';
import sales       	from './modules/sales.js';
import store		from './modules/store.js';

Vue.use(Vuex);
const createStore = () => {
	return new Vuex.Store({
		state: {
			notification    : null,
		},
		getters: {
			getterNotification	: state => state.notification
		},
		mutations: {
			setNotification: (state, payload) => {
				state.notification = payload;
			},
		},
		actions: {
			addNotification({ commit }, payload) {
				
				commit('setNotification', payload);
			},
		},
		modules: {
			customers,
			products,
			sales,
			store
		}
	});
}
export default createStore
