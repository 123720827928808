import axios 								from '../../services/axiosStore.js';
import { randomNumber, validUploadFile } 	from '../../constants/config'

export default {
	state: () => ({
		user				: null,
		isLogin				: false,
		change_password		: null,
		lead				: null,
		customer			: null,
		customerLanding		: null,
		profile_image		: null,
		customerLeadAgent	: null,
		level				: null,
		statement			: null,
		customerAgent		: null,
		customerAddresses	: [],
		validCustomer		: null
	}),
	mutations: {
		setUser: (state, payload) => {
			state.user = payload
		},
		setChangePassword: (state, payload) => {
			state.change_password = payload
		},
		setLogin: (state, payload) => {
			state.isLogin = payload
		},
		setLead(state, payload) {
			state.lead = payload
		},
		setCustomer: (state, payload) => {
			state.customer = payload
		},
		setCustomerLanding: (state, payload) => {
			state.customerLanding = payload
		},
		setCustomerLeadAgent: (state, payload) => {
			state.customerLeadAgent = payload
		},
		setProfileImage(state, payload){
			state.profile_image = payload;
		},
		setLevel(state, payload) {
			state.level = payload
		},
		setStatement(state, payload) {
			state.statement = payload
		},
		setCustomerAgent(state, payload){
			state.customerAgent = payload;
		},
		setCustomerAddresses: (state, payload) => {
			state.customerAddresses = payload
		},
		setValidCustomer: (state, payload) => {
			state.validCustomer = payload;
		}
	},
	getters: {
		getterUser				: state => !state.user && localStorage.getItem('userInfo') ? JSON.parse( localStorage.getItem('userInfo') ) : state.user,
		getterChangePassword	: state => state.change_password,
		getterLogin				: state => !state.isLogin ? !!localStorage.getItem('tokenLogin') : state.isLogin,
		getterLead				: state => state.lead,
		getterCustomer			: state => state.customer,
		getterCustomerLanding	: state => state.customerLanding,
		getterCustomerLeadAgent	: state => state.customerLeadAgent,
		getterProfileImage		: state => state.profile_image,
		getterLevel				: state => state.level,
		getterSatement      	: state => state.statement,
		getterCustomerAgent     : state => state.customerAgent,
		getterCustomerAddresses	: state => state.customerAddresses,
		getterValidCustomer		: state => state.validCustomer
	},
	actions: {
		// =================================================================
		//  CUSTOMER ACTIONS
		// =================================================================
		async getCustomer({commit}, payload) {
			
			await axios().post('/customers/find', { id_email: payload.id_email }).then(response => {
				
				const { data } = response.data;
				if( payload.origin == "landing" ){
					
					commit('setCustomerLanding', data);
				}
				else{
					
					commit('setCustomer', data);
					commit('setCustomerAddresses', data.addresses );
				}
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async getCustomerAgent({ commit }, payload){
			
			await axios().get(`${ process.env.VUE_APP_AGENTS_BACK_URL }/user/get-by-customer`).then(response => {
				
				const { data } = response.data;
				commit('setCustomerAgent', data.body);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		async validCustomer({commit}, payload) {
			
			await axios().post('/customers/valid-email', payload).then(response => {
				
				commit('setValidCustomer', response.data);
			}).catch(error => {
				
				commit('setValidCustomer', error.response.data);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async updateCustomer({commit}, payload) {
			
			await axios().put('/customers/update', payload ).then(response => {
				
				commit('setCustomer', response.data.data);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async getLevelCustomer({ commit }) {
			
			await axios().get(`${ process.env.VUE_APP_AGENTS_BACK_URL }/level/level-customer`).then(response => {
				
				const { data } = response.data;
				commit('setProfileImage', data.result.profile_image ? `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data.result.profile_image }?v=${ randomNumber() }` : null);
				commit('setLevel', data.result);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		// =================================================================
		//  LEAD ACTIONS
		// =================================================================
		async createLead({ commit }, payload) {
			
			await axios().post(`${ process.env.VUE_APP_AGENTS_BACK_URL }/lead/create`, payload).then(response => {
				
				commit('setLead', { valid: true, type: "create", data: response.data.data });
			}).catch(error => {
				
				commit('setLead', { valid: false, type: "create", data: null });
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		async updateLead({ commit }, payload) {
			
			await axios().put(`${ process.env.VUE_APP_AGENTS_BACK_URL }/lead/update`, payload).then(response => {
				
				commit('setLead', { valid: true, type: "update", data: response.data.data });
			}).catch(error => {
				
				commit('setLead', { valid: false, type: "update", data: null });
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		// =================================================================
		//  STATEMENT ACTIONS
		// =================================================================
		async getStatementByCustomer({ commit }, payload){
			
			let exist_id = payload ? `?customer=${ payload.id }`: '';
			await axios().get(`${ process.env.VUE_APP_BILING_BACK_URL }/statement/get-by-customer${ exist_id }`).then(response => {
				
				const { data } = response.data;
				commit('setStatement', data);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		// =================================================================
		//  USER ACTIONS
		// =================================================================
		async loginUser({commit, state}, payload) {
			
			await axios().post(`/users/login`, payload).then((response) => { 
				
				const { data } = response.data;
				let data_user = {
					id				: data.user.id,
					displayName		: data.user.name,
					email			: data.user.email,
					profile_image	: `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }${ data.user.profile_image }?v=${ randomNumber() }`
				};
				commit('setProfileImage', data_user.profile_image );
				
				localStorage.setItem('tokenLogin'	, data.user.token);
				localStorage.setItem('userInfo'		, JSON.stringify( data_user ));
				
				localStorage.removeItem("gclid");
				localStorage.removeItem("origin_add"); 
				localStorage.removeItem("utmSource"); 
				localStorage.removeItem("utmMedium");
				localStorage.removeItem("utmCampaign");
				localStorage.removeItem("utmTerm");
				
				commit('setUser', data_user);
				commit('setProductCart', data.cart.products);
				commit('setProductSaveLater', data.cart.save_later);
				commit('setCouponName', data.cart.coupon);
				commit('setTotalItemsCart', data.cart.total_cart);
				commit('setTotalItemsLater', data.cart.total_later);
				commit('setLogin', true);
				
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: `Hello ${ data.user.name }`, message: "Welcome to Cata1og" } });
				
			}).catch((error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async logoutUser({commit, state}, payload) {
			
			localStorage.removeItem('tokenLogin');
			localStorage.removeItem('userInfo');
			
			commit('setUser', null);
			commit('setProductCart', []);
			commit('setProductSaveLater', []);
			commit('setCouponName', "")
			commit('setTotalItemsCart', 0);
			commit('setTotalItemsLater', 0);
			
			commit('setLogin', false);
		},
		async updateCustomerPassword({commit}, payload) {
			
			await axios().put(`/users/update-password`,payload).then((response) => {
				
				let result = response.data;
				
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: result.title, message: result.message } });
				
				commit('setChangePassword', { data: { message: "Successfully Updated Password" }})    
			}).catch((error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async changeProfileImage({ commit }, payload){
			
			let result = validUploadFile( payload.file, payload.input_name, payload.max_size, payload.old_file_path, payload.extra_data );
			
			if( result.success ){
				
				await axios().post('/customers/add-profile-image', result.data ).then( ( response ) => {
					
					const { data } = response.data;
					commit('setProfileImage', `${ process.env.VUE_APP_CATALOG_BACK_URL_FILE }/${ data.url }?v=${ randomNumber() }`);
				})
				.catch( ( error ) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: error.response.data.title, message: error.response.data.message } });
				});
			}
			else{
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: result.title, message: result.message } });
			}
		},
		async findCustomerLeadAgent({ commit }, payload) {
			
			await axios().post('/users/find-agent', { email: payload.email } ).then( ( response ) => {
				
				const { data } = response.data;
				commit('setCustomerLeadAgent', data);
			})
			.catch( ( error ) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: error.response.data.title, message: error.response.data.message } });
			});
		},
		// =================================================================
		//  ADDRESS ACTIONS
		// =================================================================
		async saveCustomerAddress({commit}, payload) {
			
			await axios().put('/customers/save-address', payload ).then(response => {
				
				commit('setCustomerAddresses', response.data.data.addresses);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async deleteCustomerAddress({commit}, payload) {
			
			await axios().put('/customers/delete-address', payload ).then(response => {
				
				commit('setCustomerAddresses', response.data.data.addresses);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
	}
}
