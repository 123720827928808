import axios from 'axios';
export default () => {
	const options = {
		baseURL: process.env.VUE_APP_CMS_BACK_URL,
		headers:{
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			timeout: 15000
		}  
	};
	const instance = axios.create(options);
	return instance;
};