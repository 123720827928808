import axios                from '../../services/axiosStore.js';
import axiosCMS             from '../../services/axiosCMS.js';
import axiosFile            from '../../services/axiosFile.js';
import json_languages       from '../../data/languages.json';
import json_business_types  from '../../data/business-type.json';
import { arrayHelpers }     from '../../constants/config.js';

export default {
    state : () => ({
        company_data        : null,
        app_version         : null,
		db_countries		: [],
		db_languages		: json_languages,
        db_business_types	: json_business_types,
        db_reviews	        : [],
        brands_element      : [],
        catalogs_element    : [],
        list_blogs          : null,
        item_blog           : null,
        agent               : null
    }),
    mutations: {
        setCompanyData(state, payload) {
            state.company_data = payload
        },
        setAppVersion(state, payload) {
            state.app_version = payload
        },
        setBrandsElement(state, payload) {
            state.brands_element = payload
        },
        setCatalogsElement(state, payload) {
            state.catalogs_element = payload
        },
        setListBlogs(state, payload) {
            state.list_blogs = payload
        },
        setItemBlog(state, payload) {
            state.item_blog = payload
        },
		setCountries: (state, payload) => {
			state.db_countries = payload;
		},
		setReviews: (state, payload) => {
			state.db_reviews = payload;
		},
        setAgent: (state, payload) => {
            state.agent = payload;
        }
    },
    getters: {
        getterCompanyData       : state => state.company_data,
        getterAppVersion        : state => state.app_version,
        getterBrandsElement     : state => state.brands_element,
        getterCatalogsElement   : state => state.catalogs_element,
        getterListBlogs         : state => state.list_blogs,
        getterItemBlog          : state => state.item_blog,
		getterCountries			: state => state.db_countries,
		getterLanguages			: state => state.db_languages,
        getterBusinessTypes     : state => state.db_business_types,
        getterReviews           : state => state.db_reviews,
        getterAgent             : state => state.agent
    },
    actions: {
        // =================================================================
        //  GENERAL ACTIONS
        // =================================================================
        async updateCompanyData({commit}, payload) {
    
            commit('setCompanyData', payload);
        },
        async getGeneralSettings({commit}, payload) {
    
            await axios().get(`/general-settings/find`).then((response) => { 
                const { data } = response.data;
                commit('setCompanyData', data.company_data);
                commit('setAppVersion', data.app_version);
                if( !localStorage.getItem('app-version', data.app_version) ){

                    localStorage.setItem('app-version', data.app_version);
                }
                localStorage.setItem('general-settings', JSON.stringify( data.company_data ) );
                
            }).catch((error) => {
    
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        },
		async getCountries({commit}, payload) {

			await axiosFile().get('/documents/json/store/countries.json').then( (result) => {

				commit('setCountries', result.data);
			});
		},
		async getReviews({commit}, payload) {

			await axiosFile().get('/documents/json/store/reviews.json').then( (result) => {

				commit('setReviews', result.data);
			});
		},
        async getAgent({commit}, payload) {

			await axios().get(`/agents/find/${ payload.handle }`).then( (result) => {

                const { data } = result.data;
				commit('setAgent', data);
			});
        },
        // =================================================================
        //  HOME ACTIONS
        // =================================================================
        async getBrandsElment({ commit }) {
            
            await axios().get(`/front/slideshows/find/slider-brands?display_application=desktop_store`).then(response => {

                const { data } = response.data;
                commit('setBrandsElement', data.images);
            }).catch(error => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        },
        async getCatalogsElment({ commit }) {
            
            await axios().get(`/front/slideshows/find/slider-catalogs?display_application=desktop_store`).then(response => {

                const { data } = response.data;
                commit('setCatalogsElement', data.images);
            }).catch(error => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        },
        // =================================================================
        //  BLOG ACTIONS
        // =================================================================
        async getListBlogs({commit, state}, payload) {
            
            let page = 1;
            await axiosCMS().get(`/blog?page=${ page }`).then( async (response) => { 
                const { pages, results } = response.data;

                let all_results = [results];
                all_results[0] = all_results[0].map( (item) => { item.category = item.category.trim().toLowerCase(); item.sort_date = new Date( item.date ); return item; });
                if( page < pages ){

                    page = page + 1;
                    do {

                        await axiosCMS().get(`/blog?page=${ page }`).then((response) => { 
                            const { currentPage, results } = response.data;
                            
                            page = currentPage + 1;

                            all_results.push( results );
                            all_results[all_results.length - 1] = all_results[all_results.length - 1].map( (item) => { item.category = item.category.trim().toLowerCase(); item.sort_date = new Date( item.date ); return item; });
                            
                        }).catch((error) => {
                            
                            page = pages;
                            commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
                        });
                        
                    } while (page <= pages);
                }
                all_results = arrayHelpers.sortArray( all_results.flat(), 'category' );

                let list_blogs = all_results.reduce( (previous_item, current_item, current_index) =>{

                    if( previous_item.length > 0 && previous_item[previous_item.length - 1].name != current_item.category ){
    
                        previous_item[previous_item.length - 1].blogs.desktop = arrayHelpers.chunkArray( JSON.parse( JSON.stringify( previous_item[previous_item.length - 1].blogs.data ) ), 3 );
                        previous_item[previous_item.length - 1].blogs.mobile = JSON.parse( JSON.stringify( previous_item[previous_item.length - 1].blogs.data ) );
                        delete previous_item[previous_item.length - 1].blogs.data;
                    }
                    if( previous_item.length == 0 || ( previous_item.length > 0 && previous_item[previous_item.length - 1].name != current_item.category ) ){
    
                        previous_item.push({
                            name: current_item.category,
                            blogs: {
                                data: [current_item],
                                desktop: [],
                                mobile: []
                            }
                        });
                    }
                    else if( previous_item.length > 0 && previous_item[previous_item.length - 1].name == current_item.category ){
    
                        previous_item[previous_item.length - 1].blogs.data.push( current_item );
                    }
                    if( current_index == all_results.length - 1 ){
    
                        previous_item[previous_item.length - 1].blogs.desktop = arrayHelpers.chunkArray( JSON.parse( JSON.stringify( previous_item[previous_item.length - 1].blogs.data ) ), 3 );
                        previous_item[previous_item.length - 1].blogs.mobile = JSON.parse( JSON.stringify( previous_item[previous_item.length - 1].blogs.data ) );
                        delete previous_item[previous_item.length - 1].blogs.data;
                    }
                    return previous_item;
                }, []);
                commit('setListBlogs', list_blogs );
                
            }).catch((error) => {
    
                commit('setListBlogs', []);
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        },
        async getItemBlog({commit, state}, payload) {
            
            await axiosCMS().get(`/blog?search=${ payload.handle }`).then( async (response) => { 
                const { results } = response.data;

                commit('setItemBlog', results[0]);
                
            }).catch((error) => {
    
                commit('setItemBlog', { message: "not found" });
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        }
    }
}
