import axios                from '../../services/axiosStore.js';
import { validUploadFile }  from '../../constants/config.js';

let query_app_version = localStorage.getItem("app-version") ? `app_version=${ localStorage.getItem("app-version") }` : '';

export default {
    state: () => ({
		cart						: { products: [], details: null },
		cart_save_later				: { products: [], details: null },
        coupon                      : null,
        product                     : null,
        collection                  : null,
        list_products               : null,
        filters_collection          : [],
        searchProducts              : [],
        collectionHome              : [],
        priceCatalog                : [],
		listAddBestSellers			: [],
		notification_products		: [],
        url_download_price_catalog  : "",
		url_download_cart_file		: "",
		url_download_checkout_file	: ""
    }),
    mutations: {
		setCart: (state, payload) => {
			state.cart = payload
		},
		setCartSaveLater: (state, payload) => {
			state.cart_save_later = payload
		},
        setCoupon(state, payload) {
            state.coupon = payload
        },
        setCollection(state, payload) {
            state.collection = payload
        },
        setProduct(state, payload) {
            state.product = payload
        },
        setProducts(state, payload) {
            state.list_products = payload
        },
        setFiltersCollection(state, payload) {
            state.filters_collection = payload
        },
        setSearchProduct(state, payload) {
            state.searchProducts = payload
        },
		setListAddBestSellers: (state, payload) => {
			state.listAddBestSellers = payload; 
		},
        setCollectionHome(state, payload) {
            state.collectionHome = payload
        },
        setPriceCatalog(state, payload) {
            state.priceCatalog = payload
        },
		setNotificationProductCart: (state, payload) => {
			state.notification_products = payload; 
		},
        setDownloadPriceCatalog(state, payload) {
            state.url_download_price_catalog = payload
        },
		setDownloadCartFile: (state, payload) => { 
			state.url_download_cart_file = payload; 
		}
    },
    getters: {
		getterCart						: state => state.cart,
		getterCartSaveLater				: state => state.cart_save_later,
        getterCoupon                    : state => state.coupon,
        getterCollection                : state => state.collection,
        getterProduct                   : state => state.product,
        getterProducts                  : state => state.list_products,
        getterFiltersCollection         : state => state.filters_collection,
        getterSearchProduct             : state => state.searchProducts,
        getterCollectionHome            : state => state.collectionHome,
		getterListAddBestSellers		: state => state.listAddBestSellers,
        getterPriceCatalog              : state => state.priceCatalog,
		getterNotificationProductCart	: state => state.notification_products,
        getterDownloadPriceCatalog      : state => state.url_download_price_catalog,
		getterDownloadCartFile			: state => state.url_download_cart_file
    },
    actions: {
        // =================================================================
        //  CART ACTIONS
        // =================================================================
		async actionCart({commit}, payload){
	
			await axios().put(`/carts/${ payload.action }?${ query_app_version }`, { products: JSON.stringify(payload.products) } ).then(response => {
	
				const { data, app_version } = response.data;
				commit('setCoupon', data.coupon);
				commit('setCart', { products: data.products, details: data.details_cart });
				commit('setCartSaveLater', { products: data.save_later, details: data.details_cart_save_later });
				if( payload.action == 'add' ){
					commit('setNotificationProductCart', data.update_product_cart);
				}
				localStorage.setItem('app-version', app_version);
			}).catch(error => {
	
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async getCart({commit}, payload){
	
			await axios().get(`/carts/products?${ query_app_version }`).then(response => {
	
				const { data, app_version } = response.data;
				commit('setCoupon', data.coupon);
				commit('setCart', { products: data.products, details: data.details_cart });
				commit('setCartSaveLater', { products: data.save_later, details: data.details_cart_save_later });
				localStorage.setItem('app-version', app_version);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async downloadCartFile({commit}, payload){
			
			await axios().get(`/carts/download-cart-file?new_file=${ payload.new_file }&origin=${ payload.origin }&customer=${ payload.customer }`).then(response => {
				
				commit('setDownloadCartFile', "");
				commit('setDownloadCartFile', response.data.data.url);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async deleteCartFile({commit}, payload){
			
			await axios().post(`/carts/delete-cart-file`,{ url_file: payload.url_file }).then(response => {
	
				commit('setDownloadCartFile', "");
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		async uploadCartFile({commit, state}, payload){
			
			let result = validUploadFile( payload.file, payload.input_name, payload.max_size, payload.old_file_path, payload.extra_data );
			
			if( result.success ){
				
				await axios().post('/carts/charge-products', result.data ).then(response => {
					
					const { data } = response.data;
					if(state.cart.details.total === data.details_cart.total) {
						
						commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: 'Error: Charge Product Cart', message: 'The products do not have the conditions to be added to the cart' } });
					} 
					else {
	
						commit('setNotification', { content: { type: 'request' }, type: 'info', data: { title: 'Info: Charge Product Cart', message: `In your cart there are now ${ data.details_cart.count } products` } });
					}
					commit('setCart', { products: data.products, details: data.details_cart });
					commit('setCoupon', data.coupon);
				}).catch(error => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
				});
			}
			else{
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: result.title, message: result.message } });
			}
		},
        // =================================================================
        //  COUPON ACTIONS
        // =================================================================
		async addCouponCart({commit}, payload){
	
			await axios().put('/carts/add-coupon-code', {coupon: payload.coupon_id}).then(response => {
	
				const { data } = response.data;
				commit('setCoupon', data.coupon);
				commit('setCart', { products: data.products, details: data.details_cart });
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
        async getCoupon({ commit }, payload) {
            
            await axios().post(`${ process.env.VUE_APP_AGENTS_BACK_URL }/coupon/applicable`, payload.data).then(response => {

                const { data } = response.data;
                commit('setCoupon', data.coupon);
                if( !data.coupon ){

                    commit('setCoupon', { name: null });
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Failed Coupon Appli", message: "Coupon not exist" } });
                }
                else{
                    
                    commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: response.data.title, message: response.data.message } });
                }
            }).catch(error => {

                commit('setCoupon', { name: null });
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            })
        },
        // =================================================================
        //  COLLECTION ACTIONS
        // =================================================================
        async updateFilterCollection({ commit }, payload){

            commit('setFiltersCollection', payload );
        },
        async resetCollection({ commit }, payload){
                
            commit('setCollection', null );
        },
        async getCollection({ commit }, payload) {

            const { handle_collection, page, limit, per_page, filters, sort, sort_direction, price, brands, product_categories, variants, search, id_catalog, email_catalog } = payload;
            let body_request = {
                id_catalog      : id_catalog,
                email_catalog   : email_catalog,
                page            : page,
                get_filters     : filters,
                limit           : limit,
                per_page        : per_page,
                sort            : sort,
                sort_direction  : sort_direction,
                filters         : {
                    price           : price || null, // { min_price: 0, max_price: 0 },
                    search          : search || "",
                    brands          : brands ? JSON.stringify(brands) : "[]",
                    product_categories   : product_categories ? JSON.stringify(product_categories) :  "[]",
                    variants        : variants ? JSON.stringify(variants) : "[]"
                }
            };
            await axios().post(`/collections/${handle_collection}?${ query_app_version }`, body_request).then(response => {
                
                const { data, app_version } = response.data;
                if( response.data.success ){

                    commit('setCollection', data);
                    localStorage.setItem('app-version', app_version);
                }
                else{

                    commit('setCollection', data);
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: response.data });
                }
            }).catch(error => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            })
        },
        async searchProduct({commit}, payload) {

            if( payload.search != "" ){

                await axios().get(`/products/quick-search?page=1&limit=3&search=${ encodeURI( payload.search ) }`).then(response => {
                    
                    const { data } = response.data;
                    commit('setSearchProduct', data);
                }).catch(error => {
                    
                    commit('setSearchProduct', []);
                });
            }
            else{

                commit('setSearchProduct', null);
            }
        },
        async getCollectionHome({commit}, payload) {

            await axios().get(`/products/collection-home`).then( (response) => {
                
                const { data } = response.data;
                commit('setCollectionHome', data);
            }).catch(error => {
			
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        },
		async getListAddBestSellers({commit}, payload){
			
			await axios().get(`/carts/list-add-best-sellers?product_origin=${payload.product_origin}`, payload).then(response => {
				
				const { data } = response.data;
				commit('setListAddBestSellers', data);
			}).catch(error => {
	
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
        // =================================================================
        //  PRODUCT ACTIONS
        // =================================================================
        async getProduct({ commit }, payload) {

            let handle_collection = payload.handle_collection ? `&handle_collection=${ payload.handle_collection }` : '';
            let customer_id = payload.customer_id ? `&customer=${ payload.customer_id }` : '';
            await axios().get( `/products/${ payload.handle }/details?${ query_app_version }${ handle_collection }${ customer_id }` ).then(response => {
                
                const { data, app_version } = response.data;
                localStorage.setItem('app-version', app_version);
                commit('setProduct', data);
            }).catch(error => {
			
                commit('setProduct', "not-found");
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            });
        },
        // =================================================================
        //  PRICE CATALOG ACTIONS
        // =================================================================
        async getPriceCatalog({commit}, payload) {
            
            await axios().get(`/products/price-catalog`).then(response => {

                const { data } = response.data;
                commit('setPriceCatalog', data);
            }).catch(error => {

                commit('setPriceCatalog', [])
            })
        },
        async downloadPriceCatalog({commit}, payload) {
            
            await axios().get(`/products/download-price-catalog-file`).then(response => {

                const { data } = response.data;
				commit('setDownloadPriceCatalog', "");
				commit('setDownloadPriceCatalog', data.url);
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: response.data });
            }).catch(error => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            })
        },
        async deletePriceCatalog({commit}, payload) {
            
            await axios().get(`/products/delete-price-catalog-file`).then(response => {

                commit('setDownloadPriceCatalog', "");
            }).catch(error => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            })
        },
    },
}
