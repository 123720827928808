import axios 			from '../../services/axiosStore.js';
import axiosApiBilling 	from '../../services/axiosApiBilling.js';
import axiosApiAgent 	from '../../services/axiosApiAgent.js';

export default {
	state: () => ({
		orders					: [],
		order					: undefined,
        preorders				: [],
        listBuyAgain			: [],
		checkoutOrder			: null,
		validCheckout   		: null,
		shippingData			: null,
		businesses				: null,
		draftOrder				: null,
		statusPay				: null,
		statusPayBalance		: null,
		transactions			: [],
		url_download_cart_file	: ""
	}),
	mutations: {
		setOrders: (state, payload) => {
			state.orders = payload
		},
		setOrder: (state, payload) => {
			state.order = payload
		},
        setPreorders(state, payload) {
            state.preorders = payload
        },
        setBuyAgainProducts: (state, payload) =>  {
            state.listBuyAgain = payload
        },
		setCheckoutOrder: (state, payload) => {
			state.checkoutOrder = payload
		},
		setValidCheckout: (state, payload) => {
			state.validCheckout = payload;
		},
		setStatusPay: (state, payload) => {
			state.statusPay = payload
		},
		setStatusPayBalance: (state, payload) => {
			state.statusPayBalance = payload
		},
		setBusinesses: (state, payload) => {
			state.businesses = payload
		},
		setTransactions: (state, payload) => {
			state.transactions = payload
		},
		setDownloadTransactionsFile: (state, payload) => {
			state.url_download_cart_file = payload;
		},
		setShippingData: (state, payload) => {
			state.shippingData = payload
		},
	},
	getters: {
		getterOrder						: state => state.order,
		getterOrders					: state => state.orders,
        getterPreorders					: state => state.preorders,
        getterListBuyAgain				: state => state.listBuyAgain,
		getterCheckoutOrder				: state => state.checkoutOrder,
		getterValidCheckout				: state => state.validCheckout,
		getterStatusPay					: state => state.statusPay,
		getterStatusPayBalance			: state => state.statusPayBalance,
		getterBusinesses				: state => state.businesses,
		getterTransactions				: state => state.transactions,
		getterShippingData				: state => state.shippingData,
		getterDownloadTransactionsFile	: state => state.url_download_cart_file,
	},
	actions: {
        // =================================================================
        //  ORDER ACTIONS
        // =================================================================
		async getOrder({commit}, payload) {
			
			await axios().get(`/orders/find/${ payload.name }`).then(response => {
				
				commit('setOrder', response.data);
			}).catch(error => {
				
				commit('setOrder', null);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async getOrders({commit}, payload) {
			
			let { page, search, min_date, max_date } = payload;
			
			await axios().get(`/orders/list?page=${ page }&min_date=${ min_date }&max_date=${ max_date }&search=${ encodeURI( search ) }`).then(response => {
				
				const { data } = response.data;
				commit('setOrders', data);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async createOrder({commit}, payload) {
			
			let query_app_version = localStorage.getItem("app-version") ? `?app_version=${ localStorage.getItem("app-version") }` : '';
			
			await axios().post(`/orders/valid-checkout${ query_app_version }`, { checkout: JSON.stringify( payload.checkout ) }).then( async (response_valid) => {
				
				localStorage.setItem('app-version', response_valid.data.app_version);
				await axiosApiAgent().post(`/draft-order/create`, payload.draft_order).then( async (response_draft) => {
					
					payload.draft_order.shopify = JSON.parse( payload.draft_order.shopify );
					payload.draft_order.shopify.note_attributes.push({ name: "draft-order-db", value: response_draft.data.body._id });
					payload.draft_order.shopify = JSON.stringify( payload.draft_order.shopify );
					
					await axios().post('/orders/create', payload.draft_order).then( async (response) => {
						
						const  { data } = response.data;
						commit( 'setCheckoutOrder', data );
					}).catch( (error) => {
						
						const  { data, title, message } = error.response.data;
						
						if( data.status != "order-not-created" ){
							
							commit( 'setCheckoutOrder', data );
						}
						else{
							
							commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: title, message: message }, isCheckout: true });
						}
					});
				}).catch(error => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Create Order", message: error.response.data.message }, isCheckout: true });
				});
			}).catch(error => {
				
				commit('setValidCheckout', false);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: error.response.data.title, message: error.response.data.message }, isCheckout: true });
			})
		},
		async payOrder({commit}, payload) {
			
			let query_app_version = localStorage.getItem("app-version") ? `?app_version=${ localStorage.getItem("app-version") }` : '';
	
			await axios().post(`/orders/valid-checkout${ query_app_version }`, { checkout: JSON.stringify( payload.checkout ) }).then( async (response) => {
				
				localStorage.setItem('app-version', response.data.app_version);
				await axiosApiAgent().post(`/draft-order/create`, payload.draft_order).then( async (response) => {
					
					const { body } = response.data;
					
					payload.draft_order.shopify = JSON.parse( payload.draft_order.shopify );
					payload.draft_order.shopify.note_attributes.push({ name: "draft-order-db", value: body._id });
					payload.draft_order.shopify = JSON.stringify( payload.draft_order.shopify );
					payload.payment.db_draft_id = body._id;
					
					await axios().post('/orders/create/draft/shopify', { shopify_data: payload.draft_order.shopify }).then( async (response) => {
						
						const { data } = response.data;
						payload.payment.draft_id 		= data.id;
						payload.payment.note_attributes = data.note_attributes;
						payload.payment.user_id 		= data.user_id;
						payload.payment.customer_id 	= data.customer_id;
						await axiosApiBilling().post(`/payment/pay-order/${ payload.payment.customer_id }`, payload.payment).then(response => {
							
							if( response.data.result == "approved"){
								
								commit('setStatusPay', response.data);
							}
							else{
								
								commit('setStatusPay', { error: "pay" });
								commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Order", message: response.data.message }, isCheckout: true });
							}
						}).catch(error => {
							
							commit('setStatusPay', { error: "pay" });
							commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Order", message: error.response.data.message }, isCheckout: true });
						});
					}).catch( (error) => {
						
						commit('setStatusPay', { error: "create-draft-sh" });
						commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: error.response.data.title, message: error.response.data.message }, isCheckout: true });
					});
				}).catch(error => {
					
					commit('setStatusPay', { error: "create-draft-db" });
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Create Order", message: error.response.data.message }, isCheckout: true });
				});
			}).catch(error => {
	
				commit('setValidCheckout', false);
				if( error.response.status != 403 ){
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
				}
			})
		},
		async payBalance({commit}, payload) {
			
			await axiosApiBilling().post(`/payment/pay-order/${ payload.payment.customer_id }`, payload.payment).then(response => {
				
				commit('setStatusPayBalance', response.data);
				
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Balance", message: error.response.data.message } });
			});
		},
        // =================================================================
        //  PREORDERS ACTIONS
        // =================================================================
        async listProductPreorders({commit}, payload) {
            await axios().get(`/preorders/get-products`).then(response => {
    
                const { data } = response.data;
                commit( 'setPreorders', data );
            }).catch(error => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            })
        },
        // =================================================================
        //  BUYAGAIN ACTIONS
        // =================================================================
        async listBuyAgainProducts({commit}, payload) {
            
            await axios().get(`/buy-again/list?sort=last_purcharse&sort_direction=desc`).then(response => {
                
                const { data } = response.data;
                commit('setBuyAgainProducts', data);
            }).catch(error => {
    
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
            })
        },
        // =================================================================
        //  CHECKOUT ACTIONS
        // =================================================================
		async resetShippingData({commit}, payload) {
	
			commit('setShippingData', payload);
		},
		async resetValidCheckout({commit}, payload) {
	
			commit('setValidCheckout', payload);
		},
		async getBusinesses({commit}, payload) {
			
			await axios().get(`/businesses/list`).then(response => {
				
				const { data } = response.data;
				commit( 'setBusinesses', data );
				
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		async calculateShipping({commit}, payload) {
	
			await axios().post(`/orders/shipping-options?product_origin=${ payload.product_origin }`,  payload ).then(response => {
	
				const { data } = response.data;
				commit('setShippingData', data.details);
				commit('setCoupon', data.coupon);
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		},
		async validCheckout({commit}, payload) {
	
			let query_app_version = localStorage.getItem("app-version") ? `?app_version=${ localStorage.getItem("app-version") }` : '';
	
			await axios().post(`/orders/valid-checkout${ query_app_version }`, { checkout: JSON.stringify( payload ) }).then(response => {
				
				localStorage.setItem('app-version', response.data.app_version);
				
				commit('setValidCheckout', response.data.data.valid);
			}).catch(error => {
	
				commit('setValidCheckout', false);
				if( error.response.status != 403 ){
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
				}
			})
		},
        // =================================================================
        //  TRANSACTION ACTIONS
        // =================================================================
		async getTransactions({commit}, payload) {
			
			await axios().get(`${ process.env.VUE_APP_BILING_BACK_URL }/transactions?page=${ payload.page }&search=${ encodeURI(payload.search) }&from=${ payload.min_date }&to=${ payload.max_date }`).then( (response) => {
				
				const { data } = response.data;
				commit( 'setTransactions', data );
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async downloadExportTransactionsFile({commit}, payload) {

			await axios().get(`/orders/transactions/download-file?page=${ payload.page }&from=${ payload.min_date }&to=${ payload.max_date }`).then( (response) => {

				commit('setDownloadTransactionsFile', "");
				commit('setDownloadTransactionsFile', response.data.data.url);
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: response.data });
			}).catch( (error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			});
		},
		async deleteExportTransactionsFile({commit}, payload) {
			
			await axios().post(`/orders/transactions/delete-file`,{ url_file: payload.url_file }).then(response => {
	
				commit('setDownloadTransactionsFile', "");
			}).catch(error => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: error.response.data });
			})
		}
	}
}