import axios from 'axios';
export default () => {
	const options = {
		baseURL: process.env.VUE_APP_CATALOG_BACK_URL,
		headers:{
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'x-access-token': localStorage.getItem('tokenLogin') || process.env.VUE_APP_CATALOG_TOKEN,
			timeout: 15000
		}  
	};
	const instance = axios.create(options);
	return instance;
};