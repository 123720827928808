import axios from 'axios';
export default () => {
    const options = {
        baseURL: process.env.VUE_APP_AGENT_API_URL,
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': process.env.VUE_APP_AGENTS_TOKEN,
            timeout: 15000
        }   
    };
    const instance = axios.create(options);
    return instance;
};